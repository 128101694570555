import React, {useEffect, useState} from "react";
import "./featuredInfo.css";
import "../../../node_modules/draft-js/dist/Draft.css";
import {PaidOutlined, PaymentsOutlined, WarningAmber,} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import {Button} from "@material-ui/core";
import {AccountBalanceWalletOutlined, ApartmentOutlined,} from "@material-ui/icons";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {basicSchema} from "../schemas/Schemas";
import {motion} from "framer-motion";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import EditorState from "draft-js/lib/EditorState";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import {convertToRaw} from "draft-js";
import draftToHtml from "draftjs-to-html";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {ApiDetails, KEY} from "../../dummyData";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import * as yup from "yup";
import "yup-phone";
import CryptoJS from "crypto-js";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HtmlTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));

export default function FeaturedInfo(props) {

    let details = CryptoJS.AES.decrypt(
        localStorage.getItem("tenant_details"),
        KEY
    );

    let originalText = details.toString(CryptoJS.enc.Utf8);

    const [detailsSet, setDetailsSet] = useState(false);
    //Loading Screen
    let [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
            onClick={null}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
    const [tenantDetails, setTenantDetails] = useState(
        new Map(
            Object.entries({
                tenant_id: "",
                first_name: "",
                last_name: "",
                national_id: "",
                email_address: "",
                phone_number: "",
                house_number: "",
                rent: "",
                bill: "",
                arrears: "",
                account_balance: "",
                amount: "",
                state: "",
                temp_account_balance: "",
                minimum_days_to_vacate: "",
                date_created: "",
                Wallet: "",
                status: "",
            })
        )
    );
    useEffect(() => {
        fetchTenantDetails();
    }, []);

    let initialPaymentValues = {
            amount: tenantDetails.get("status") === "COMPLETED" ? 0 : tenantDetails.get("Wallet"),
            phone_number: tenantDetails.get("phone_number"),
        }
    ;

    const fetchTenantDetails = () => {


        setBackDrop(
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={true}
                onClick={null}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        );
        fetch(ApiDetails + "pegasus/visionary/tenant/app/tenantDetails", {
            method: "POST",
            body: JSON.stringify({
                tenant_id: new Map(
                    Object.entries(JSON.parse(originalText))
                ).get("tenant_id"),
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    console.log("Tenant Details");
                    console.log(data);
                    setTenantDetails(new Map(Object.entries(data)));
                    setDetailsSet(true);
                    setBackDrop(<></>);
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached",
                        type: "warning",
                    });
                } else if (response.status === 400) {
                    props.snackBar({
                        text: "Invalid Credentials Provided.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    const processPayment = async (values) => {

        setTenantLoading(true);

        if (values.phone_number.toString().startsWith("0")) {
            values.phone_number = "254" + values.phone_number.toString().substring(1);
        } else if (
            values.phone_number.toString().startsWith("1") ||
            values.phone_number.toString().startsWith("7")
        ) {
            values.phone_number = "254" + values.phone_number.toString();
        }

        if (
            tenantDetails.get("ho_number") === 0 ||
            tenantDetails.get("till_number") === 0 ||
            tenantDetails.get("short_code") === "" ||
            tenantDetails.get("pass_key") === ""
        ) {
            props.snackBar({
                text: "Payment details have not been set for your property",
                type: "error",
            });
            setTenantLoading(false);
            handleClose();
            return;
        }

        let body = {
            BusinessShortCode: tenantDetails.get("ho_number").toString(),
            Password: "",
            Timestamp: "",
            TransactionType: "CustomerBuyGoodsOnline",
            Amount: values.amount,
            PartyA: values.phone_number,
            // PartyB: "6122464",
            PartyB: tenantDetails.get("till_number").toString(),
            PhoneNumber: values.phone_number,
            CallBackURL:
                "https://api.pegasus-pms.co.ke/api/" + "pegasus/visionary/payments/",
            AccountReference: "ITORAMA SOLUTIONS",
            TransactionDesc: "Payment of rent",
            houseNumber: tenantDetails.get("account_number"),
            tenant_id: tenantDetails.get("tenant_id"),
            landlord_id: tenantDetails.get("landlord_id"),
            short_code: tenantDetails.get("short_code"),
            pass_key: tenantDetails.get("pass_key"),
            consumer_key: tenantDetails.get("consumer_key"),
            consumer_secret: tenantDetails.get("consumer_secret"),
        };

        // console.log(tenantDetails);
        fetch(ApiDetails + "pegasus/visionary/mpesa/StkPush", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setTenantLoading(false);
                    props.snackBar({
                        text: "Kindly wait For mpesa prompt on your phone",
                        type: "success",
                    });
                } else if (response.status === 403) {
                    // console.log(data);
                    setTenantLoading(false);
                    props.snackBar({
                        text: new Map(Object.entries(data)).get("Message"),
                        type: "error",
                    });
                }
                handleClose();
            })
            .catch((err) => {
                console.log(err);
                setTenantLoading(false);
                props.snackBar({
                    text: "Payment Not Successful",
                    type: "error",
                });
            });

        fetchTenantDetails();
    };

    const [warningModal, setWarningModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [editorState, setEditorState] = React.useState(() =>
        EditorState.createEmpty()
    );
    // console.log(new Date().getDate());

    const [dateToday] = useState(new Date());
    const [dateToVacate, setDateToVacate] = useState(new Date());
    const initialValues2 = {
        first_name: tenantDetails.get("first_name"),
        last_name: tenantDetails.get("last_name"),
        phone_number: tenantDetails.get("phone_number"),
        national_id: tenantDetails.get("national_id"),
        unit: tenantDetails.get("house_number"),
        property_name: tenantDetails.get("property_name"),
        landlord_id: tenantDetails.get("landlord_id"),
        tenant_id: tenantDetails.get("tenant_id"),
    };
    const dateSchema = yup.object().shape({
        phone_number: yup.string().phone("KE", true).required("Required"),
    });

    const [vacatingNoticeModal, setVacatingNoticeModal] = useState(false);
    const [tenantLoading, setTenantLoading] = React.useState(false);
    const [valuesAfterWarning, setValuesAfterWarning] = useState({});

    const handleClose = () => {
        setPaymentModal(false);
        setVacatingNoticeModal(false);
        setWarningModal(false);
    };
    const sendVacatingNoticeAfterWarning = () => {

        // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/app/AddNotice", {
            method: "POST",
            body: JSON.stringify(valuesAfterWarning),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    fetchTenantDetails();
                    props.snackBar({
                        text: "Notice Successfully Uploaded",
                        type: "success",
                    });
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached",
                        type: "warning",
                    });
                } else if (response.status === 400) {
                    props.snackBar({
                        text: "Invalid Credentials Provided.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
        setTenantLoading(false);
        handleClose();
    };

    function toMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber);

        return date.toLocaleString("en-US", {
            month: "long",
        });
    }

    const sendVacatingNotice = (values) => {

        setTenantLoading(true);
        const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        // const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        // const data = htmlToDraft;
        let minimumMonthsBeforeVacating = tenantDetails.get(
            "minimum_days_to_vacate"
        );

        let dateJoined = new Date(tenantDetails.get("date_created").toString());
        let vacatingDate = new Date(dateToVacate.toJSON());
        let difference =
            vacatingDate.getFullYear() * 12 +
            vacatingDate.getMonth() -
            (dateJoined.getFullYear() * 12 + dateJoined.getMonth());

        values = {
            ...values,
            // date_to_vacate_house: new Date(dateToVacate.toJSON()),
            date_to_vacate_house:
                toMonthName(new Date(dateToVacate.toJSON()).getMonth()) +
                " " +
                new Date(dateToVacate.toJSON()).getDate() +
                ", " +
                new Date(dateToVacate.toJSON()).getFullYear(),
            date_notice_given: new Date(),
            reasons_for_vacating: JSON.stringify(
                convertToRaw(editorState.getCurrentContent())
            ),
        };

        //Check if year is valid
        if (difference < minimumMonthsBeforeVacating) {
            //Show warning of not getting deposit back
            setValuesAfterWarning(values);
            handleClose();
            setVacatingWarningModalNotice(
                "Please note that you need to have occupied the premises for atleast " +
                minimumMonthsBeforeVacating +
                " months to qualify for refund of deposit.\nIf you " +
                "proceed, your deposit shall not be refunded"
            );
            setWarningModal(true);
            setTenantLoading(false);
            return;
        } else if (
            new Date(dateToVacate.toJSON()).getFullYear() < dateToday.getFullYear()
        ) {
            props.snackBar({
                text: "Invalid date to vacate.",
                type: "error",
            });
            setTenantLoading(false);
            return;
        }
        //Check if date is valid
        else if (
            new Date(dateToVacate.toJSON()).getFullYear() ===
            dateToday.getFullYear() &&
            new Date(dateToVacate.toJSON()).getMonth() < dateToday.getMonth()
        ) {
            props.snackBar({
                text: "Invalid date to vacate.",
                type: "error",
            });
            setTenantLoading(false);
            return;
        }
        //Check if date to vacate is before end-month of same month
        else if (new Date(dateToVacate.toJSON()).getDate() < 28 && new Date(dateToVacate.toJSON()).getMonth() === dateToday.getMonth()) {

            setValuesAfterWarning(values);
            handleClose();
            setVacatingWarningModalNotice(
                "Notice to vacate are given one month in advance.\nIf you " +
                "                proceed, you risk not being refunded your deposit"
            );
            setWarningModal(true);
            setTenantLoading(false);
            // props.snackBar({
            //     text: "You can only vacate property after the 28th of the month",
            //     type: "error",
            // });
            // setTenantLoading(false);
            return;
        }
        //Check if date to day is past 5th and notice is for the same month
        else if (
            dateToday.getDate() >= 3 &&
            new Date(dateToVacate.toJSON()).getMonth() === dateToday.getMonth()
        ) {
            //Show warning of risking not getting deposit back
            setValuesAfterWarning(values);
            handleClose();
            setVacatingWarningModalNotice(
                "Notice to vacate are given before the 3rd of the month.\nIf you " +
                "                proceed, you risk not being refunded your deposit"
            );
            setWarningModal(true);
            setTenantLoading(false);
            // props.snackBar({
            //   text: "Notice to vacate are given before the 5th of the month. You risk not being refunded your deposit",
            //   type: "warning",
            // });
            return;
        }
        //Check if date to vacate is before end-month of another month
        else if (new Date(dateToVacate.toJSON()).getDate() < 28) {

            props.snackBar({
                text: "You can only vacate property at the end of the month",
                type: "error",
            });
            setTenantLoading(false);
            return;
        } else {
            // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
            fetch(ApiDetails + "pegasus/visionary/tenant/app/AddNotice", {
                method: "POST",
                body: JSON.stringify(values),
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        fetchTenantDetails();
                        props.snackBar({
                            text: "Notice Successfully Uploaded",
                            type: "success",
                        });
                    } else if (response.status === 500) {
                        props.snackBar({
                            text: "Something Went Wrong. Try Again Later",
                            type: "error",
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                });
        }


        setTenantLoading(false);
        handleClose();
    };

    const [vacatingWarningModalNotice, setVacatingWarningModalNotice] =
        useState();

    return (
        <>
            {backDrop}

            <Dialog
                open={warningModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogTitle
                    style={{
                        color: "#398DD2",
                    }}
                >
                    Warning
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    margin: 10,
                                    textAlign: "center",
                                }}
                            >
                                <WarningAmber
                                    style={{
                                        scale: "3",
                                        color: "#EEB931",
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    flex: 3,
                                }}
                            >
                                {vacatingWarningModalNotice}
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleClose();
                            sendVacatingNoticeAfterWarning();
                        }}
                    >
                        <span style={{color: "green"}}>Proceed</span>
                    </Button>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Cancel</span>
                    </Button>
                </DialogActions>
            </Dialog>

            {detailsSet ? (

                <>
                    <Dialog
                        open={paymentModal}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                    >

                        <DialogTitle
                            style={{
                                color: "#398DD2",
                            }}
                        >
                            Make Payment
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                <Formik
                                    initialValues={initialPaymentValues}
                                    validationSchema={basicSchema}
                                    onSubmit={processPayment}
                                >
                                    {(props) => (
                                        <Form noValidate>
                                            <p>Enter number to be prompted to make payment.</p>
                                            <Field
                                                as={TextField}
                                                name="phone_number"
                                                label="Phone Number"
                                                error={
                                                    props.errors.phone_number &&
                                                    props.touched.phone_number
                                                }
                                                helperText={
                                                    <ErrorMessage className="error" name="phone_number"/>
                                                }
                                                required
                                                variant="outlined"
                                                style={{marginTop: "10px", width: "100%"}}
                                            />
                                            <Field
                                                as={TextField}
                                                name="amount"
                                                label="Amount"
                                                type="number"
                                                required
                                                variant="outlined"
                                                error={props.errors.amount && props.touched.amount}
                                                helperText={
                                                    <ErrorMessage className="error" name="amount"/>
                                                }
                                                style={{marginTop: "10px", width: "100%"}}
                                            />

                                            <br/>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Button onClick={handleClose}>
                                                    <span style={{color: "red"}}>Cancel</span>
                                                </Button>
                                                <LoadingButton
                                                    type={"submit"}
                                                    size="small"
                                                    // onClick={addTenant}
                                                    loading={tenantLoading}
                                                    startIcon={<SendIcon/>}
                                                    style={{color: "green"}}
                                                    loadingPosition="start"
                                                >
                                                    Submit
                                                </LoadingButton>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>


                    {/*Add Tenant MODAL*/}
                    <Dialog
                        maxWidth={true}
                        open={vacatingNoticeModal}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle
                            style={{
                                width: "90%",
                            }}
                        >
                            <div
                                style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    color: "#398DD2",
                                }}
                            >
                                Vacating Notice
                            </div>
                        </DialogTitle>
                        <DialogContent
                            style={{
                                padding: "10px",
                            }}
                        >
                            <Formik
                                initialValues={initialValues2}
                                validationSchema={dateSchema}
                                onSubmit={sendVacatingNotice}
                            >
                                {(props) => (
                                    <Form noValidate>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Field
                                                as={TextField}
                                                name="first_name"
                                                label="First Name"
                                                variant="outlined"
                                                error={
                                                    props.errors.first_name && props.touched.first_name
                                                }
                                                helperText={
                                                    <ErrorMessage className="error" name="first_name"/>
                                                }
                                                required
                                                disabled={true}
                                                style={{marginTop: "10px", width: "35%"}}
                                            />
                                            <Field
                                                as={TextField}
                                                name="last_name"
                                                label="Last Name"
                                                error={
                                                    props.errors.last_name && props.touched.last_name
                                                }
                                                helperText={
                                                    <ErrorMessage className="error" name="last_name"/>
                                                }
                                                required
                                                variant="outlined"
                                                disabled={true}
                                                style={{marginTop: "10px", width: "35%"}}
                                            />
                                            <Field
                                                as={TextField}
                                                name="national_id"
                                                label="National ID"
                                                type="number"
                                                required
                                                variant="outlined"
                                                disabled={true}
                                                style={{marginTop: "10px", width: "25%"}}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Field
                                                as={TextField}
                                                name="phone_number"
                                                label="Phone Number"
                                                error={
                                                    props.errors.phone_number &&
                                                    props.touched.phone_number
                                                }
                                                className={
                                                    props.errors.phone_number &&
                                                    props.touched.phone_number
                                                        ? "input-error"
                                                        : ""
                                                }
                                                helperText={
                                                    <ErrorMessage className="error" name="phone_number"/>
                                                }
                                                required
                                                variant="outlined"
                                                style={{marginTop: "10px", width: "49%"}}
                                            />
                                            <Field
                                                as={TextField}
                                                name="unit"
                                                label="House Number"
                                                error={props.errors.unit && props.touched.unit}
                                                helperText={
                                                    <ErrorMessage className="error" name="unit"/>
                                                }
                                                required
                                                disabled={true}
                                                variant="outlined"
                                                style={{marginTop: "10px", width: "49%"}}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    disabled={true}
                                                    value={dateToday}
                                                    onChange={() => {
                                                    }}
                                                    label="Date Today"
                                                    inputFormat="MM/DD/YYYY"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            name="date_notice_given"
                                                            error={
                                                                props.errors.date_notice_given &&
                                                                props.touched.date_notice_given
                                                            }
                                                            helperText={
                                                                <ErrorMessage
                                                                    className="error"
                                                                    name="date_notice_given"
                                                                />
                                                            }
                                                            style={{marginTop: "10px", width: "49%"}}
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    label="Date To Vacate"
                                                    inputFormat="MM/DD/YYYY"
                                                    value={dateToVacate}
                                                    onChange={(newValue) => setDateToVacate(newValue)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            name="date_to_vacate_House"
                                                            error={
                                                                props.errors.date_to_vacate_House &&
                                                                props.touched.date_to_vacate_House
                                                            }
                                                            helperText={
                                                                <ErrorMessage
                                                                    className="error"
                                                                    name="date_notice_given"
                                                                />
                                                            }
                                                            style={{marginTop: "10px", width: "49%"}}
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <RichTextEditor
                                                richTextValue={editorState}
                                                change={(value) => {
                                                    setEditorState(value);
                                                }}
                                            />
                                            {/*<Editor editorState={editorState} onChange={setEditorState}/>*/}
                                        </div>

                                        <br/>
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "end",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <Button onClick={handleClose}>
                                                <span style={{color: "red"}}>Cancel</span>
                                            </Button>
                                            <LoadingButton
                                                type={"submit"}
                                                size="small"
                                                // onClick={addTenant}
                                                loading={tenantLoading}
                                                startIcon={<SendIcon/>}
                                                style={{color: "green"}}
                                                loadingPosition="start"
                                            >
                                                Submit
                                            </LoadingButton>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </DialogContent>
                    </Dialog>
                </>
            ) : (
                <></>
            )}

            {/*DELETE MODAL*/}

            <div className="featured">
                <div className="featuredTop">

                    <div
                        className="featuredItem"
                        style={{backgroundColor: "rgb(170,250,170)"}}
                    >
                        <span className="featuredTitle">
              <div
                  style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                  }}
              >
                Wallet Balance
                <AccountBalanceWalletOutlined style={{color: "#398DD2"}}/>
              </div>
            </span>
                        <div className="featuredMoneyContainer">
                            <HtmlTooltip
                                title={<React.Fragment>{"Account Balance"}</React.Fragment>}
                            >
                                {tenantDetails.get("status") === "COMPLETED" ? (<>
                                    {parseInt(
                                        tenantDetails.get("Wallet").toString().replace(",", "")
                                    ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "KSH",
                                    })}</>) : (<>
                                    {parseInt("0").toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "KSH",
                                    })}</>)}
                            </HtmlTooltip>
                        </div>
                        <div className="featuredSub">
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                    onClick={() => {
                                        setPaymentModal(true);
                                    }}
                                >
                                    <span>Deposit </span>{" "}
                                    <PaidOutlined
                                        style={{
                                            marginLeft: "5px",
                                            fontSize: "20px",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="featuredItem"
                        // style={{ backgroundColor: "rgb(250,170,170)" }}
                    >
            <span className="featuredTitle">
              <div
                  style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                  }}
              >
                Property
                <ApartmentOutlined style={{color: "#398DD2"}}/>
              </div>
            </span>
                        <div className="featuredMoneyContainer">
                            <div style={{display: "flex"}}>
                <span className="featuredMoney">
                  {tenantDetails.get("property_name")}
                </span>
                            </div>
                        </div>
                        <div className="featuredSub">
                            Unit {tenantDetails.get("house_number")}
                        </div>
                    </div>
                    <div className="featuredItem">
                        <span className="featuredTitle">Rent Agreed</span>
                        <div className="featuredMoneyContainer">
              <span className="featuredMoney">
                {tenantDetails.get("rent").toLocaleString("en-US", {
                    style: "currency",
                    currency: "KSH",
                })}
              </span>
                        </div>
                        <div className="featuredSub">
                            Rent Is Due By 5<sup>th</sup>
                        </div>
                    </div>
                </div>

                <div className="featuredBottom">

                    {tenantDetails.get("status") !== "COMPLETED" ? (
                        <>
                            <motion.div
                                // animate={{y:10}}
                                // animate={{y:[0,10,0,10,0,10,0],scale:[0.9,1,0.9,1,0.9,1,0.9,1,0.9,1]}}
                                animate={{scale: [0.95, 1, 0.95]}}
                                transition={{duration: 3, repeat: Infinity}}
                                // animate={{rotate:rotate ? 300 : 0}}
                                // initial={{y:0}}
                                // whileHover={{scale:1.1}}
                                // drag
                                // dragConstraints={{left:50,top:50,right:50,bottom:50}}
                                whileDrag={{scale: 1.1}}
                                // transition={{type:"spring",bounce:3}}
                                className="featuredItem"
                                style={{backgroundColor: "rgb(250,170,170)"}}
                            >
                                <span className="featuredTitle">Pending Bill</span>
                                <div className="featuredMoneyContainer">
                                    <span className="featuredMoney">
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                  {parseInt(
                                      tenantDetails.get("Wallet").toString().replace(",", "")
                                  ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "KSH",
                                  })}
                                    <Button
                                        style={{
                                            background: "#398DD2",
                                            color: "white",
                                        }}
                                        onClick={() => {
                                            setPaymentModal(true);
                                        }}
                                    >
                                    Pay Now
                                    <PaymentsOutlined
                                        style={{
                                            marginLeft: "5px",
                                        }}
                                    />
                                  </Button>
                                </div>
                              </span>
                                </div>
                                <div className="featuredSub">
                                    Pay On Time To Avoid Penalties
                                </div>
                            </motion.div>
                        </>
                    ) : (
                        <>
                            <div
                                className="featuredItem"
                                style={{backgroundColor: "rgb(170,250,170)"}}
                            >
                                <span className="featuredTitle">Pending Bill</span>
                                <div className="featuredMoneyContainer">
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        {parseInt(
                                            tenantDetails.get("Wallet").toString().replace(",", "")
                                        ).toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "KSH",
                                        })}
                                        <Button
                                            style={{
                                                background: "#398DD2",
                                                color: "white",
                                            }}
                                            onClick={() => {
                                                setPaymentModal(true);
                                            }}
                                        >
                                            Pay Now
                                            <PaymentsOutlined
                                                style={{
                                                    marginLeft: "5px",
                                                }}
                                            />
                                        </Button>
                                    </div>
                                </div>
                                <div className="featuredSub">All Bills Cleared</div>
                            </div>
                        </>
                    )}

                    {/*    {tenantDetails.get("arrears") > 0 ? (*/}
                    {/*        <motion.div*/}
                    {/*            animate={{scale: [0.95, 1, 0.95]}}*/}
                    {/*            transition={{duration: 3, repeat: Infinity}}*/}
                    {/*            style={{backgroundColor: "rgb(250,170,170)"}}*/}
                    {/*            className="featuredItem"*/}
                    {/*        >*/}
                    {/*            <span className="featuredTitle">Total Arrears</span>*/}
                    {/*            <div className="featuredMoneyContainer">*/}
                    {/*<span className="featuredMoney">*/}
                    {/*  <div*/}
                    {/*      style={{*/}
                    {/*          width: "100%",*/}
                    {/*          display: "flex",*/}
                    {/*          justifyContent: "space-between",*/}
                    {/*          alignItems: "center",*/}
                    {/*      }}*/}
                    {/*  >*/}
                    {/*    {tenantDetails.get("arrears").toLocaleString("en-US", {*/}
                    {/*        style: "currency",*/}
                    {/*        currency: "KSH",*/}
                    {/*    })}*/}
                    {/*      <Button*/}
                    {/*          style={{*/}
                    {/*              background: "#398DD2",*/}
                    {/*              color: "white",*/}
                    {/*          }}*/}
                    {/*          onClick={() => {*/}
                    {/*              setPaymentModal(true);*/}
                    {/*          }}*/}
                    {/*      >*/}
                    {/*      Pay Now*/}
                    {/*      <PaymentsOutlined*/}
                    {/*          style={{*/}
                    {/*              marginLeft: "5px",*/}
                    {/*          }}*/}
                    {/*      />*/}
                    {/*    </Button>*/}
                    {/*  </div>*/}
                    {/*</span>*/}
                    {/*            </div>*/}
                    {/*            <div className="featuredSub">All Arrears Cleared</div>*/}
                    {/*        </motion.div>*/}
                    {/*    ) : (*/}
                    {/*        <motion.div className="featuredItem">*/}
                    {/*            <span className="featuredTitle">Total Arrears</span>*/}
                    {/*            <div className="featuredMoneyContainer">*/}
                    {/*<span className="featuredMoney">*/}
                    {/*  <div*/}
                    {/*      style={{*/}
                    {/*          width: "100%",*/}
                    {/*          display: "flex",*/}
                    {/*          justifyContent: "space-between",*/}
                    {/*          alignItems: "center",*/}
                    {/*      }}*/}
                    {/*  >*/}
                    {/*    {tenantDetails.get("arrears").toLocaleString("en-US", {*/}
                    {/*        style: "currency",*/}
                    {/*        currency: "KSH",*/}
                    {/*    })}*/}
                    {/*      <Button*/}
                    {/*          style={{*/}
                    {/*              background: "#398DD2",*/}
                    {/*              color: "white",*/}
                    {/*          }}*/}
                    {/*          onClick={() => {*/}
                    {/*              setPaymentModal(true);*/}
                    {/*          }}*/}
                    {/*      >*/}
                    {/*      Pay Now*/}
                    {/*      <PaymentsOutlined*/}
                    {/*          style={{*/}
                    {/*              marginLeft: "5px",*/}
                    {/*          }}*/}
                    {/*      />*/}
                    {/*    </Button>*/}
                    {/*  </div>*/}
                    {/*</span>*/}
                    {/*            </div>*/}
                    {/*            <div className="featuredSub">All Arrears Cleared</div>*/}
                    {/*        </motion.div>*/}
                    {/*    )}*/}


                    {tenantDetails.get("state") === "NOTICE" ? (

                        <motion.div
                            animate={{scale: [0.95, 1, 0.95]}}
                            transition={{duration: 3, repeat: Infinity}}
                            style={{backgroundColor: "#CBD8EC"}}
                            className="featuredItem"
                        >
                        <span className="featuredTitle">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                  Available Notice
                    {/*<ApartmentOutlined style={{color: "#398DD2"}}/>*/}
                </div>
              </span>
                            <div className="featuredMoneyContainer">
                <span className="featuredMoney">

                  <div
                      style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                      }}
                  >
                    <span>
                      You Requested To vacate on
                        {": " +
                            new Map(
                                Object.entries(tenantDetails.get("notice"))
                            ).get("date_to_vacate_house")}
                    </span>
                  </div>
                </span>
                            </div>
                            <div className="featuredSub">
                                Date notice given{" "}
                                {": " +
                                    new Map(Object.entries(tenantDetails.get("notice"))).get(
                                        "date_created"
                                    )}
                            </div>
                        </motion.div>
                    ) : (
                        <div className="featuredItem">
              <span className="featuredTitle">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                  Notice
                    {/*<ApartmentOutlined style={{color: "#398DD2"}}/>*/}
                </div>
              </span>
                            <div className="featuredMoneyContainer">
                <span className="featuredMoney">
                  <div
                      style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                      }}
                  >
                    <span>Vacating Notice</span>
                    <Button
                        style={{
                            background: "#398DD2",
                            color: "white",
                        }}
                        onClick={() => {
                            setVacatingNoticeModal(true);
                        }}
                    >
                      Fill Form
                      <PaymentsOutlined
                          style={{
                              marginLeft: "5px",
                          }}
                      />
                    </Button>
                  </div>
                </span>
                            </div>
                            <div className="featuredSub">Ts & Cs Apply</div>
                        </div>
                    )}
                </div>
            </div>

        </>
    );
}
